<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>
        <div>Filters</div>

        <v-spacer />

        <v-btn icon small @click="hideFilter = !hideFilter">
          <v-icon small color="primary">mdi-chevron-down</v-icon>
        </v-btn>

        <v-btn icon small>
          <v-icon small color="primary" @click="resetFilter()">mdi-reload</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text v-show="!hideFilter" class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              outlined
              dense
              hide-details
              clearable
              name="name"
              label="Name"
              v-model="query.name"
              placeholder="e.g. 2 Northdown"
            />
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field
              outlined
              dense
              hide-details
              clearable
              name="address"
              label="Address"
              v-model="query.address"
              placeholder="e.g. London"
            />
          </v-col>

          <v-col cols="12" sm="3">
            <v-select
              v-model="query.status"
              :items="statuses"
              :item-text="'text'"
              :item-value="'value'"
              label="Status"
              dense
              clearable
              @click:clear="clear('status')"
              outlined
            ></v-select>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field
              outlined
              dense
              hide-details
              clearable
              name="zip"
              label="Zip"
              v-model="query.code"
              placeholder="e.g. 530 WN"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title class="d-flex">
        <v-row>
          <v-spacer />
          <v-col cols="12" sm="2" xl="3" class="text-left text-sm-right">
            <v-row>
              <v-col cols="12">
                <v-btn block outlined color="primary" @click="exportExcel()" :loading="loading.export">Export</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table :headers="headers" :items="desserts" hide-default-footer>
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ item.createdAt | dateTime }}</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span>{{ item.status | capitalize }}</span>
        </template>
        <template v-slot:footer>
          <v-pagination
            v-if="totalPage"
            class="text-right text-body-2 py-2 "
            circle
            :total-visible="5"
            color="primary"
            v-model="query.page"
            :length="totalPage"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { VenueService } from '@services';

export default {
  data() {
    return {
      hideFilter: false,
      query: {
        name: '',
        address: '',
        status: 'active',
        code: '',
        promoter: '',
        page: 1,
        venues: [],
      },
      statuses: [
        { text: 'Active', value: 'active' },
        { text: 'Pending', value: 'pending' },
        { text: 'Reject', value: 'reject' },
      ],
      totalPage: '',
      headers: [
        {
          text: 'Venue Name',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '200px',
        },
        { text: 'Address', value: 'address', sortable: false, width: '400px' },
        { text: 'Zip', value: 'zip', sortable: false, width: '150px' },
        { text: 'Status', value: 'status', sortable: false, width: '100px' },
        { text: 'Created On', value: 'createdAt', sortable: false, width: '200px' },
      ],
      desserts: [],
      loading: {
        export: false,
      },
    };
  },
  created() {
    Object.assign(this.query, this.$getState());
    this.query.venues = this.$user.venues;
    this.getVenues();
  },
  methods: {
    async getVenues() {
      this.$setState(this.query);
      const data = await VenueService.get(_.cloneDeep(this.query));
      if (data) {
        this.desserts = data.venue;
        this.totalPage = Number(parseInt((data.totalCount + 10 - 1) / 10));
      }
    },
    resetFilter() {
      this.query.name = '';
      this.query.address = '';
      this.query.status = '';
      this.query.code = '';
      this.query.page = 1;
      this.getVenues();
    },
    changeRoute() {
      this.$next({ name: 'promoter.venues.add' });
    },
    editItem(item) {
      this.$next({ name: 'promoter.venues.edit', params: { id: item._id } });
    },
    async deleteItem(item) {
      this.$confirm({
        title: `Are you sure you want to delete this venue?`,
        resolve: async () => {
          await VenueService.delete(item._id);
          this.getVenues();
        },
      });
    },
    async exportExcel() {
      this.loading.export = true;
      const exportdata = await VenueService.exportExcel(this.query);
      const url = URL.createObjectURL(new Blob([exportdata]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Venues-${moment().format('DD/MM/YYYY')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      this.loading.export = false;
    },
  },
  watch: {
    'query.name': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.name && this.query.name.length > 2) {
          this.query.page = 1;
          this.getVenues();
        }
        if (!this.query.name) {
          this.query.page = 1;
          this.getVenues();
        }
      }, 600);
    },
    'query.address': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.address && this.query.address.length > 2) {
          this.query.page = 1;
          this.getVenues();
        }
        if (!this.query.address) {
          this.query.page = 1;
          this.getVenues();
        }
      }, 600);
    },
    'query.status': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.status && this.query.status.length > 2) {
          this.query.page = 1;
          this.getVenues();
        }
        if (!this.query.status) {
          this.query.page = 1;
          this.getVenues();
        }
      }, 600);
    },
    'query.code': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.code && this.query.code.length > 2) {
          this.query.page = 1;
          this.getVenues();
        }
        if (!this.query.code) {
          this.query.page = 1;
          this.getVenues();
        }
      }, 600);
    },
    'query.page': function() {
      this.getVenues();
    },
  },
};
</script>
